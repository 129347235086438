import 'regenerator-runtime/runtime';
import { call, put } from 'redux-saga/effects';

import API, { methods } from '../API';

import { handleGenericError } from '../utils';
import { actions } from '../../reducers/qc';

export default function* getAllRigs(action) {
  try {
    const response = yield call(API, {
      method: methods.GET,
      path: 'rigs',
    });
    const { rigs } = response;
    yield put(actions.getAllRigsSuccess(rigs));
  } catch (e) {
    yield call(handleGenericError, action, e);
  }
}
